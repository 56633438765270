<style src="./MobileNps.scss" lang="scss" scoped></style>

<template>
  <section class="mobile-nps">
    <div class="mobile-nps__content">
      <img :src="smileURL" />

      <span class="mobile-nps__title">{{ evaluateMessage }}</span>

      <div v-if="showAttendanceRatting" class="mobile-nps__starts">
        <button
          v-for="item in 5"
          :key="item"
          class="mobile-nps__start-btn"
          v-touch:tap="() => setStaRate(item)"
        >
          <i :class="['far', 'fa-star', {'fas': starRate >= (item +1) }]" />
        </button>
      </div>

      <div v-if="showIndicateRatting" class="mobile-nps__starts">
        <button
          v-for="item in 10"
          :key="item"
          class="mobile-nps__evaluate-indication"
          v-touch:tap="() => handleRateNps(item)"
        >
         {{ item }}
        </button>
      </div>

      <button v-if="!showReturnToHome" class="btn-evaluate-later" v-touch:tap="handleFinishAttendance">Avaliar mais tarde!</button>
      <button v-else class="btn-evaluate-later" v-touch:tap="handleFinishAttendance">Retonar ao Início!</button>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import cloneDeep from 'lodash.clonedeep'

import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";

import MovementLogs from "@/helpers/movement_logs";
import Firebase from '@/helpers/firebase/index';

const BASE_FOM = Object.freeze({
  nps_id_atendimento: null,
  nps_id_paciente: null,
  nps_atendimento_nota: null,
  nps_indicacao_nota: null,
})

export default {
  name: 'MobileNps',
  data() {
    return {
      npsService: null,
      form: cloneDeep(BASE_FOM),
      smileURL: require('@/assets/smile.svg'),
    }
  },

  computed: {
    starRate() {
      return this.form.nps_atendimento_nota || null
    },
    evaluateMessage() {
      if (this.form.nps_atendimento_nota === null) {
        return 'Como você avalia o nosso atendimento?'
      }

      if (this.form.nps_indicacao_nota === null) {
        return 'Em uma escala de 0 a 10, o quanto você indicaria a nossa empresa a um amigo ou colega?'
      }

      if (this.form.nps_atendimento_nota !== null && this.form.nps_indicacao_nota !== null) {
        return 'Obrigado pela sua avaliação, sua opinião é muito importante para nós!'
      }
    },
    showAttendanceRatting() {
      return this.form.nps_atendimento_nota === null
    },
    showIndicateRatting() {
      return this.form.nps_atendimento_nota !== null && this.form.nps_indicacao_nota === null
    },
    showReturnToHome() {
      return this.form.nps_atendimento_nota !== null && this.form.nps_indicacao_nota !== null
    }
  },

  mounted () {
    this.npsService = new ServicoAll(
      "/api/pep/nps/save",
      new ServicoHttp(this)
    );

    this.init();
  },

  methods: {
    async init() {
      const pac = await this.$store.dispatch("getLocalStoragePatient");
      await Firebase.updatePatientSpecificKey(pac.pac_id, "comando", null);

      // ! Firebase LOG
      const objLog = { ...pac }
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
      MovementLogs.saveLog(1800, timeAction, objLog)
    },
    setStaRate(rate) {
      this.form.nps_atendimento_nota = rate
    },
    async handleFinishAttendance () {
      // ! Firebase LOG
      const pac = await this.$store.dispatch("getLocalStoragePatient");
      const objLog = { ...pac }
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
      MovementLogs.saveLog(1802, timeAction, objLog)

      if (window?.ReactNativeWebView) {
        return window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'FINISH_CALL' })
        )
      }

      window.location = `${this.appDeeplink}://open`
    },
    async handleRateNps(value) {
      this.form.nps_indicacao_nota = value

      const payload = cloneDeep(this.form)
      const pac = await this.$store.dispatch("getLocalStoragePatient");
      const ateId = this.$route.query.video || null
      payload.nps_id_paciente = pac.pac_id
      payload.nps_id_atendimento = ateId

      const { data: response } = await this.npsService.post(payload);

      if (!response.status) {
        return this.$toast.error('Falha ao enviar avaliação, tente novamente mais tarde!')
      }

      return this.$toast.success('Avaliação enviada com sucesso!')
    }
  },
}
</script>
